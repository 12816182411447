import {waitLoad} from "@blax-modules/base-front-end/resources/js/utilities/load";

export class AlertColor {
    constructor(aparams = {}) {

        waitLoad().then(()=>{
            this.init();
        });
    }

    init() {
        let alertsElems = document.querySelectorAll('.alert-banner');
        if (alertsElems) {
            this.setupBanners(alertsElems);
        }
    }

    setupBanners(elems) {
        for(let x = 0; x < elems.length; x++) {
            this.setBackgroundColor(elems[x]);
            this.setTextColor(elems[x]);
            this.setLinkColor(elems[x]);
        }
    }

    setBackgroundColor(elem) {
        const backgroundColor = elem.getAttribute('data-backgroundcolor');
        if(backgroundColor) {
            elem.style.backgroundColor = "#" + backgroundColor;
        }
    }
    setTextColor(elem) {
        const textColor = elem.getAttribute('data-textcolor');
        if(textColor) {
            elem.style.color = "#" + textColor;
        }
    }
    setLinkColor(elem) {
        const hoverColor = elem.getAttribute('data-linkcolorhover');
        const textColor = elem.getAttribute('data-linkcolor');
        if(textColor) {
            elem.querySelector('span').style.color = "#" + textColor;
            elem.querySelectorAll('path').forEach(curPathElem => curPathElem.style.stroke = "#" + textColor);
        }
        if(textColor && hoverColor) {
            elem.querySelector('a').onmouseover = () => {
                elem.querySelector('span').style.color = "#" + hoverColor;
                elem.querySelectorAll('path').forEach(curPathElem => curPathElem.style.stroke = "#" + hoverColor);
            }
            elem.querySelector('a').onmouseleave = () => {
                elem.querySelector('span').style.color = "#" + textColor;
                elem.querySelectorAll('path').forEach(curPathElem => curPathElem.style.stroke = "#" + textColor);
            }
        }
    }
}
