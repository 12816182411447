import {waitLoad} from "./load";

export class TextMaxlength {
    maxTextLength = 150;
    constructor() {

        waitLoad().then(()=>{
            //this.init();
        });
    }

    init() {
        let textElems = document.querySelectorAll('.logo-list--with-bg__logo__text');
        if(textElems && textElems.length > 0) {
            for(let x = 0; x < textElems.length; x++) {
                const elemInnerHtml = textElems[x].innerHTML;
                console.log(elemInnerHtml.length + ' / ' + this.maxTextLength);
                if(elemInnerHtml.length > this.maxTextLength) {
                    textElems[x].innerHTML = elemInnerHtml.substring(0, this.maxTextLength) + ' ...';
                }
            }
        }
    }
}
