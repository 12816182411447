import { providerList } from './utilities/providers'

import './libs/aos';
import './libs/object-fit-polyfill';
import './libs/svg-inject';
import './libs/picturefill';
import './libs/corejs';
import './libs/custom-polyfills';
import './libs/lazyload';

import { TextMaxlength } from './utilities/text-maxlength.js';
providerList.instantiate('TextMaxlength', TextMaxlength);


import { Cover } from './pictures/cover';
providerList.instantiate('Cover', Cover);

/* import { SlideBtn } from "./buttons/slide-btn";
providerList.instantiate('SlideBtn', SlideBtn); */

import { BindMessage } from "./ui/message";
providerList.instantiate('BindMessage', BindMessage, 'gdpr');

import { Preloader } from './ui/preloader';
providerList.instantiate('Preloader', Preloader);

import { ProgressBar } from './ui/progress-bar';
providerList.instantiate('ProgressBar', ProgressBar);

import BackToTop from './ui/back-to-top';
providerList.instantiate('BackToTop', BackToTop);

import { VideoLink } from './ui/video-link';
providerList.instantiate('VideoLink', VideoLink);
